import React from 'react'
import { useLangContext } from '../../context/lang.context'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Headline from '../../components/Headline'
import Button from '../../components/Button'
import { s, sHtml, sCenteredSpaced } from '../../style'

import shipping from '../../images/product/benefits/import.svg'
import quality from '../../images/product/benefits/quality.svg'
import time from '../../images/product/benefits/time.svg'

const content = {
  title: {
    pl: 'Informacje techniczne',
    en: '',
  },
  desc: {
    pl: `Poznaj naszych ekspertów.`,
    en: ``,
  },
}


const ProductTechnicalInfo = ({ sizes, desc, slug }) => {
  const buttonBack = {
    text: {
      pl: 'Specyfikacja',
      en: 'Specification',
    },
    link: {
      pl: `/specyfikacja-` + slug,
      en: `/specification-` + slug,
    }
  }

  const { lang } = useLangContext()
  return (
    <>
      <section css={sSectionWrapper}>
        <Headline h={2} title={content.title[lang]} large gradient />
        {sizes && (
          <div css={sizesImageWrapper}>
            <GatsbyImage
              alt={``}
              image={getImage(sizes.gatsbyImageData)}
            />
          </div>
        )}
        <div css={productOrderBenefits}>
          <div>
            <img
              src={time}
              alt={``}
            />
            <strong>Szybki czas dostawy</strong>
          </div>
          <div>
            <img
              src={quality}
              alt={``}
            />
            <strong>Sprawdzona jakość</strong>
          </div>
          <div>
            <img
              src={shipping}
              alt={``}
            />
            <strong>Organizacja importu</strong>
          </div>
        </div>
        {desc && (
          <div css={productDescSection}>
            <article css={sHtml} dangerouslySetInnerHTML={{ __html: desc.childMarkdownRemark.html }} />
          </div>
        )}

        <div css={sCenteredSpaced}>
          <Button glow link={buttonBack.link[lang]}>
            {buttonBack.text[lang]}
          </Button>
        </div>

      </section>
    </>
  )
}

const sSectionWrapper = {
  maxWidth: 1500,
  margin: '0 auto',
  padding: '3rem 4rem',
  [s.md_1024]: {
    padding: '0 1rem 1rem',
  },
}

const sizesImageWrapper = {
  display: 'flex',
  justifyContent: 'center',
  padding: '3rem 0'
}

const productOrderBenefits = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '120px',
  padding: '3rem 1rem',
  'div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gap: '20px',
    'img': {
      maxWidth: '90px',
      margin: '0 auto',
    }
  },
  'div:first-of-type': {
    'img': {
      maxWidth: '150px'
    }
  },
  [s.md_1024]: {
    padding: '2rem 1rem 1rem',
    gap: '40px',
    'div': {
      'img': {
        maxWidth: '60px',
      }
    },
    'div:first-of-type': {
      'img': {
        maxWidth: '100px'
      }
    },
  },
  [s.sm_down_2]: {
    gap: '30px',
    'div': {
      fontSize: '0.85rem'
    },
  }
}

const productDescSection = {
  padding: '3rem 0 0',
  'article': {
    padding: '0',
  },
  'article h2, article h3': {
    padding: '1rem 0 0.5rem',
  },
  'article ul li, article ol li': {
    color: '#2b2a29',
  },
  'article p': {
    marginBottom: '1rem',
  },
}

export default ProductTechnicalInfo
