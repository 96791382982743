import React, { useState } from 'react';
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import isEqual from 'lodash.isequal'
import { GatsbyImage, getSrc } from 'gatsby-plugin-image'
import { StoreContext } from '../context/store-context'
import { AddToCart } from '../components/shopify/add-to-cart'
import { QuoteButton } from '../components/shopify/quote-button'
import { NumericInput } from '../components/shopify/numeric-input'
import ProductTeam from '../sections/product/ProductTeam'
import ProductTechnicalInfo from '../sections/product/ProductTechnicalInfo'
import ProductMap from '../sections/product/ProductMap'
import ProductSteps from '../sections/product/ProductSteps'
import ProductServices from '../sections/product/ProductServices'
import ProductIndustries from '../sections/product/ProductIndustries'
import ProductFinancing from '../sections/product/ProductFinancing'
import ProductPartners from '../sections/product/ProductPartners'
import { formatPrice } from '../utils/format-price'
import { sTitle } from '../style'
import RelatedProducts from '../sections/product/RelatedProducts';
import {
  productSection,
  productBox,
  noImagePreview,
  optionsWrapper,
  pricesWrapper,
  priceOmnibusWrapper,
  priceWrapper,
  priceType,
  priceValue,
  selectVariant,
  breadcrumb,
  addToCartStyle,
  productDescription,
  productButtonWrapper,
  productImagesSliderWrapper,
  productImagesSlider,
  productImagesSliderItem,
  productImagesSliderThumbs,
  productImagesSliderThumbsItem,
} from '../style/shop/product-page.module.css'

export default function Product({
  data: { product, additionalInfo, products },
}) {
  const {
    handle,
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    descriptionHtml,
    images,
    metafields,
    seo,
    collections,
  } = product

  const team = additionalInfo?.productTeam;
  const orderSteps = additionalInfo?.orderSteps;
  const relatedProducts = products?.nodes
  const sizesImage = additionalInfo?.containerSizesImage
  const productDesc = additionalInfo?.productDescription

  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === '') {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const netPriceValue = metafields.filter(
    (metafield) => metafield.key === 'net_price'
  )

  const netPrice = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    netPriceValue[0].value
  )

  const netPriceOmnibusValue = metafields.filter(
    (metafield) => metafield.key === 'net_price_omnibus'
  )

  const netPriceOmnibus = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    netPriceOmnibusValue[0].value
  )

  const rentPriceValue = metafields.filter(
    (metafield) => metafield.key === 'container_rent'
  )

  let rentPrice = '';

  if (rentPriceValue.length > 0) {
    rentPrice = formatPrice(
      priceRangeV2.minVariantPrice.currencyCode,
      rentPriceValue[0]?.value
    )
  }

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = true || images.length > 1

  const seoImagePath = getSrc(images[0])

  const [activeThumb, setActiveThumb] = useState();


  return (
    <Layout white>
      <Seo
        title={seo.title}
        desc={seo.description}
        imageUrl={seoImagePath}
        url={`sklep/` + handle}
      />
      <section className={[productSection]}>
        <div className={productBox}>
          {hasImages && (
            <div style={{width: '100%'}}>
              <div className={productImagesSliderWrapper}>
                <Swiper
                  loop={true}
                  spaceBetween={10}
                  modules={[Navigation, Thumbs]}
                  navigation={true}
                  grabCursor={true}
                  thumbs={{ swiper: activeThumb, slideThumbActiveClass: 'swiper-slide-thumb-active' }}
                  className={productImagesSlider}
                  style={{
                    '--swiper-navigation-size': '32px',
                    ' --swiper-navigation-color': '#fff'
                  }}
                  css={{
                    '.swiper-button-prev, .swiper-button-next': {
                      '&::after': {
                        'background': '#fff',
                        'padding': '15px 5px',
                        'boxShadow': '0 0 15px #ddd',
                        'borderRadius': '5px',
                        'color': '#5E62BD'
                      }
                    }
                  }}
                  >
                  {images.map((item, index) => (
                    <SwiperSlide 
                      key={index}
                      className={productImagesSliderItem} 
                    >
                      <GatsbyImage
                        objectFit='contain'
                        // style={{
                        //   width: '100%'
                        // }}
                        alt={
                          item.altText
                            ? item.altText
                            : `Product Image of ${title} #${index + 1}`
                        }
                        image={item.gatsbyImageData}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Swiper
                  onSwiper={setActiveThumb}
                  loop={false}
                  spaceBetween={20}
                  slidesPerView={'3'}
                  // watchSlidesVisibility={true}
                  watchSlidesProgress={true}
                  breakpoints={{
                    576: {
                      slidesPerView: 4,
                    },
                    1024: {
                      slidesPerView: 5,
                    }
                  }}
                  modules={[Navigation, Thumbs]}
                  className={productImagesSliderThumbs}
                  css={{
                    '.swiper-slide-thumb-active': {
                      'border': '2px solid #5E62BD',
                      'borderRadius': '5px'
                    }
                  }}
                  >
                  {images.map((item, index) => (
                    <SwiperSlide 
                      key={index}
                      className={productImagesSliderThumbsItem}
                      >
                      <GatsbyImage
                        objectFit='contain'
                        alt={
                          item.altText
                            ? item.altText
                            : `Product Image of ${title} #${index + 1}`
                        }
                        image={item.gatsbyImageData}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          )}
          {!hasImages && (
            <span className={noImagePreview}>No Preview image</span>
          )}
          <div>
            <div className={breadcrumb}>
              {collections?.map((collection, id, collections) =>
                id + 1 !== collections.length ? (
                  <Link to={`/sklep/` + collection.handle}>
                    {collection.title + ` ,`}
                  </Link>
                ) : (
                  <Link to={`/sklep/` + collection.handle}>
                    {collection.title}
                  </Link>
                )
              )}
            </div>
            <h1 css={[sTitle, { fontSize: '2rem', marginBottom: '1rem' }]}>
              {title}
            </h1>
            {descriptionHtml && (
              <div
                className={productDescription}
                dangerouslySetInnerHTML={{ __html: descriptionHtml }}
              />
            )}
            <div className={pricesWrapper}>
              <div className={priceWrapper}>
                <span className={priceType}>Cena netto</span>
                <h2 className={priceValue}>
                  <span>{netPrice}</span>
                </h2>
              </div>
              <div className={priceWrapper}>
                <span className={priceType}>Cena brutto</span>
                <h2 className={priceValue}>
                  <span>{price}</span>
                </h2>
              </div>
              {rentPriceValue.length > 0 &&
                <div className={priceWrapper}>
                  <span className={priceType}>Cena wynajmu **</span>
                  <h2 className={priceValue}>
                    <span>{rentPrice + `/dzień`}</span>
                  </h2>
                </div>
               }
            </div>
            <div className={priceOmnibusWrapper}>
              <p>
                Najniższa cena w ciągu 30 dni:{' '}
                <strong>{netPriceOmnibus}</strong>{' '}(netto)
              </p>
              {rentPriceValue.length > 0 &&
                <p>** Cena wynajmu od 6-12 miesięcy. Cena nie zawiera dostawy. </p>
              }
            </div>
            {hasVariants && (
              <fieldset className={optionsWrapper}>
                {hasVariants &&
                  options.map(({ id, name, values }, index) => (
                    <div className={selectVariant} key={id}>
                      <select
                        aria-label='Variants'
                        onChange={(event) => handleOptionChange(index, event)}>
                        <option value=''>{`Wybierz ${name}`}</option>
                        {values.map((value) => (
                          <option value={value} key={`${name}-${value}`}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
              </fieldset>
            )}
            <div className={addToCartStyle}>
              <NumericInput
                aria-label='Quantity'
                onIncrement={() => setQuantity((q) => Math.min(q + 1, 20))}
                onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                onChange={(event) => setQuantity(event.currentTarget.value)}
                value={quantity}
                min='1'
                max='20'
              />
              <div className={productButtonWrapper}>
                <AddToCart
                  variantId={productVariant.storefrontId}
                  quantity={quantity}
                  available={available}
                />
                {rentPriceValue.length > 0 &&
                  <QuoteButton label={`Wynajmij`} outline />
                }
                  <QuoteButton label={`Wyceń`} /> 
              </div>
            </div>
          </div>
        </div>
      </section>

      {team && (
        <ProductTeam team={team} />
      )}
      
      <ProductTechnicalInfo sizes={sizesImage} desc={productDesc} slug={handle} />

      <ProductMap />

      {orderSteps && (
        <ProductSteps steps={orderSteps} />
      )}

      <ProductServices />
      <ProductIndustries />
      <ProductFinancing />
      <ProductPartners />

      {relatedProducts &&(
        <RelatedProducts related={relatedProducts} />
      )}

    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $slug: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      handle
      description
      descriptionHtml
      productType
      tags
      collections {
        handle
        title
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      seo {
        title
        description
      }
      storefrontId
      metafields {
        value
        type
        namespace
        key
      }
      images {
        altText
        id
        gatsbyImageData(layout: CONSTRAINED, width: 640, aspectRatio: 1)
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        #compareAtPrice
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
    additionalInfo: contentfulProdukt(slug: { eq: $slug }) {
      productDescription {
        childMarkdownRemark {
          html
        }
      }
      productTeam {
        teamMemberName
        teamMemberPosition
        teamMemberLinkedIn
        teamMemberPhone
        teamMemberEmail
        teamMemberBio {
          childMarkdownRemark {
            html
          }
        }
        teamMemberImage {
          gatsbyImageData(layout: CONSTRAINED, width: 1000)
        }
      }
      containerSizesImage {
        gatsbyImageData(layout: CONSTRAINED, width: 750)
      }
      orderSteps {
        stepName
        stepDesc {
          childMarkdownRemark {
            html
          }
        }
        stepImage {
          gatsbyImageData(layout: CONSTRAINED, width: 960)
        }
      }
    }
    longDescription: contentfulDodatkowyTekst(slug: { eq: $slug }) {
      text: childContentfulDodatkowyTekstBodyTextNode {
        childrenMarkdownRemark {
          html
        }
      }
    }
    products: allShopifyProduct(
      #filter: {collections: {elemMatch: {id: {eq: $id}}}}
      filter: { id: { ne: $id } } #sort: { fields: [publishedAt], order: ASC } #limit: 24
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`
