import React from 'react'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { ProductCard } from '../../components/ProductCard'
import { s } from '../../style'

const content = {
  title: {
    pl: 'Inne produkty',
    en: '',
  },
  desc: {
    pl: `Poznaj naszych ekspertów.`,
    en: ``,
  },
}

const RelatedProducts = ({related}) => {
  const { lang } = useLangContext()
  return (
    <>
      <section css={sSectionWrapper}>
        <Headline
          h={2}
          title={content.title[lang]}
          large
          gradient
        />
        <Swiper
          // install Swiper modules
          modules={[Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          slidesPerGroup={1}
          pagination={{ enabled: false, clickable: true }}
          breakpoints={{
            767: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              pagination: {
                enabled: true,
              },
            },
            1279: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              pagination: {
                enabled: true,
              },
            },
          }}
          css={[productRelatedProducts]}
          style={{
            '--swiper-pagination-color': '#20214f',
          }}>
          {related.map((p, index) => (
            <SwiperSlide key={index}>
              <ProductCard product={p} key={p.id} eager={index === 0} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  )
}

const sSectionWrapper = {
  maxWidth: 1500,
  margin: '0 auto',
  padding: '3rem 4rem',
  [s.md_1024]: {
    padding: '3rem 1rem 3rem',
  }
}

const productRelatedProducts = {
  marginTop: '3rem',
}

export default RelatedProducts