import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getShopifyImage } from 'gatsby-source-shopify'
import { StoreContext } from '../context/store-context'
import { useFormContext } from '../context/form.context'
import { formatPrice } from '../utils/format-price'
import { AddToCart } from '../components/shopify/add-to-cart'
import { QuoteButton } from '../components/shopify/quote-button'
import Button from '../components/Button'
import { colors, s } from '../style'
import {
  productCardStyle,
  productHeadingStyle,
  productImageStyle,
  productDetailsStyle,
  productPrice,
  productCardButtonWrapper,
  productCardHeadlineWrapper
} from '../style/shop/product-card.module.css'

export function ProductCard({ product, eager }) {
  const {
    title,
    orderNo,
    priceRangeV2,
    handle,
    variants,
    variants: [initialVariant],
    images: [firstImage],
    storefrontImages,
    metafields
  } = product

  const { client } = React.useContext(StoreContext)
  const { setFormOpen } = useFormContext()

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  // const productTitle = orderNo ? orderNo + '. ' + title : title

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    priceRangeV2.minVariantPrice.amount
  )

  const netPriceValue = metafields.filter(metafield => metafield.key === 'net_price');

  const netPrice = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    netPriceValue[0].value
  )
  
  const defaultImageHeight = 200
  const defaultImageWidth = 200
  let storefrontImageData = {}
  if (storefrontImages) {
    const storefrontImage = storefrontImages.edges[0].node
    try {
      storefrontImageData = getShopifyImage({
        image: storefrontImage,
        layout: 'fullWidth',
        width: defaultImageWidth,
        height: defaultImageHeight,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const btn = {
    text: 'Wyceń',
    action: () => setFormOpen(true),
    glow: true,
  }

  const hasImage =
    firstImage || Object.getOwnPropertyNames(storefrontImageData || {}).length

  return (
    <Link
      className={productCardStyle}
      to={`/sklep/` + handle}
      aria-label={`View ${title} product page`}>
      {hasImage ? (
        <div className={productImageStyle} data-name='product-image-box'>
          <GatsbyImage
            alt={firstImage?.altText ?? title}
            image={firstImage?.gatsbyImageData ?? storefrontImageData}
            loading={eager ? 'eager' : 'lazy'}
          />
        </div>
      ) : (
        <div style={{ height: defaultImageHeight, width: defaultImageWidth }} />
      )}
      <div className={productDetailsStyle}>
        <div className={productCardHeadlineWrapper}>
          <h2 as='h2' className={productHeadingStyle}>
            {title}
          </h2>
        </div>
        <div className={productPrice}><span>Cena netto:</span> {netPrice}</div>
        <div className={productPrice}><span>Cena brutto:</span> {price}</div>
        <div className={productCardButtonWrapper}>
          <AddToCart
            variantId={productVariant.storefrontId}
            quantity={1}
            available={available}
          />
          <QuoteButton label={`Wyceń`}/>
        </div>
      </div>
    </Link>
  )
}

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    id
    title
    handle
    images {
      id
      altText
      gatsbyImageData(width: 450)
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
    metafields {
      value
      type
      namespace
      key
    }
    variants {
      availableForSale
      storefrontId
      title
      price
      #compareAtPrice
      selectedOptions {
        name
        value
      }
    }
  }
`
