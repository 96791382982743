import React from 'react'
import { useLangContext } from '../../context/lang.context'
import { useMatchMedia } from '../../utils/useMatchMedia'
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import Headline from '../../components/Headline'
import { graphql, useStaticQuery } from 'gatsby'
import Tiles from '../../components/Tiles'
import Tile from '../../components/Tile'
import { s } from '../../style'

const content = {
  title: {
    pl: 'Usługi',
    en: 'Checkout the most popular TSL services',
  },
  desc: {
    pl: 'Realizujemy usługi komplementarne do sprzedaży oraz dystrybucji kontenerów.',
    en: 'Choose the best quality transport, forwarding or logistics service. Take advantage of the offers dedicated to your business and see what we can do for you.',
  },
}

const ProductServices = (small, large, subtitleOnTop) => {
  const { lang } = useLangContext()
  const isDesktopResolution = useMatchMedia('(min-width:1200px)', true)
  const { children } = useStaticQuery(graphql`
    {
      children: allContentfulService(sort: { fields: name }) {
        nodes {
          name
          slug
          lang {
            slug
          }
          image {
            gatsbyImageData(width: 480, quality: 100, formats: [JPG])
          }
        }
      }
    }
  `)

  const links = children.nodes
    .filter((node) => node.lang.slug === lang)
    .map((node) => ({
      slug: node.slug,
      name: node.name,
      image: node.image,
    }))

  return (
    <>
      <section css={sSectionWrapper}>
        <Headline h={2} title={content.title[lang]} large gradient />
        {!isDesktopResolution && (
          <Swiper
            // install Swiper modules
            modules={[Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            slidesPerGroup={1}
            autoplay={{delay: 5000}}
            pagination={{ enabled: true, clickable: true }}
            breakpoints={{
              767: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                pagination: {
                  enabled: true,
                },
              },
            }}
            css={{ marginTop: '3rem' }}
            style={{
              '--swiper-pagination-color': '#20214f',
            }}
            >
            {links?.map((item, id) => (
              <SwiperSlide key={id}>
                <Tile
                  data={item}
                  key={id}
                  slider
                  subtitleOnTop={subtitleOnTop}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </section>
      {isDesktopResolution && <Tiles data={links} noMargin />}
    </>
  )
}

const sSectionWrapper = {
  maxWidth: 1500,
  margin: '0 auto',
  padding: '3rem 4rem',
  [s.md_1024]: {
    padding: '3rem 1rem 0rem',
  },
}

export default ProductServices
