import * as React from "react"
import { StoreContext } from "../../context/store-context"
import { useFormContext } from '../../context/form.context'
import { sButton, sButtonTrade, sButtonTradeQuote } from "../Button"

export function QuoteButton({ variantId, label, outline, quantity, available, ...props }) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)
  const { setFormOpen } = useFormContext()

  function QuoteButton(e) {
    e.preventDefault()
    setFormOpen(true)
  }

  return (
    <button
      type="button"
      css={[sButton, sButtonTradeQuote, {boxShadow: 'unset'}, outline && sButtonOutline]}
      onClick={QuoteButton}
    >{label}
    </button>
  )
}

const sButtonOutline = {
  background: 'transparent',
  border: '2px solid',
  padding: 'calc(1rem - 2px) 3rem',
}
