import * as React from "react"
import { StoreContext } from "../../context/store-context"
import { sButton, sButtonTrade } from "../Button"

export function AddToCart({ variantId, quantity, available, ...props }) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)
  }

  return (
    <button
      type="submit"
      css={[sButton, sButtonTrade, {boxShadow: 'unset'}]}
      onClick={addToCart}
      disabled={!available || loading}
      {...props}
    >
      {available ? "Zamów" : "Produkt niedostępny"}
    </button>
  )
}
